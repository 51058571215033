<template>
    <div>
        <div @click="goBack()">
            Back
        </div>
        Email Template
    </div>
</template>

<script>
    export default {
        name: "EmailTemplate",
        data() {
            return {

            }
        },
        methods: {
            goBack() {
                this.$router.go(-1)
            },
        }
    }
</script>

<style scoped lang="scss">

</style>